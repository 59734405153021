input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.pagination-container input {
    color: #fff;
    background-color: var(--color-secondary);
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    font-size: 14px;
}

.left-icon, .right-icon {
    cursor: pointer;
    color: #fff;
}

.left-icon {
    margin-right: 20px;
}

.right-icon {
    margin-left: 20px;
}

.left-outer-icon, .right-outer-icon {
    cursor: pointer;
    color: #fff;
    width: 26px;
    height: 26px;
}

.left-outer-icon {
    margin-right: 10px;
}

.right-outer-icon {
    margin-left: 10px;
}

