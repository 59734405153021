.github-issues-wrapper {
    margin: 50px;
}

.github-issues-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.github-issues-container {
    height: 600px;
    width: 600px;
}

.github-issue {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: var(--color-secondary);
    margin-bottom: 5px;
    transition: all 300ms ease-in-out;
}

.github-issue:hover {
    padding-left: 20px;
    background-color: var(--color-alt);
}

.github-issue img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-left: 20px;
}

.issue-labels {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.issue {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    height: 10px;
    border-radius: 20px;
    padding: 12px;
    margin-right: 10px;
    color: #fff;
}

.issue-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.issue-info h1 {
    font-size: 16px;
    max-height: 38px;
    overflow: hidden;
}
.issue-info h1, p {
    color: #fff;
}