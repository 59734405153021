.github-commits-wrapper {
    margin: 50px;
}

.github-commit-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    color: #fff;
    margin-bottom: 20px;
}

.github-commits-container {
    height: 600px;
    width: 600px;
}

.github-commit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: var(--color-secondary);
    margin-bottom: 5px;
    transition: all 300ms ease-in-out;
}

.github-commit:hover {
    padding-left: 20px;
    background-color: var(--color-alt);
}

.github-commit img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-left: 20px;
}

.commit-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.commit-info h1 {
    font-size: 16px;
}
.commit-info h1, p {
    color: #fff;
}

.author-info {
    display: flex;
    flex-direction: row;
}

.author-info p {
    color: rgb(160, 160, 160);
    margin-left: 10px;
}

.loading-text {
    color: #fff;
}