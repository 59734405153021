.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--color-primary);
}

.login-wrapper img {
  width: 200px;
  height: 200px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 450px;
  width: 400px;
  background-color: var(--color-secondary);
  border-radius: 50px;
  margin-left: 250px;
  margin-right: 150px;
}

.login-container h1 {
  color: #fff;
  text-align: center;
  font-size: 28px;
  letter-spacing: 1.2px;
}

.login-box {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.login-box input {
  background-color: var(--color-primary);
  border: none;
  outline: none;
  width: 250px;
  height: 40px;
  padding: 10px;
  color: #fff;
  margin: 10px 0 10px 0;
}

.login-box input:active {
  border:none;
}

.login-box button {
  cursor: pointer;
  border: none;
  background-color: var(--color-neutral);
  color: #fff;
  width: 250px;
  height: 45px;
  margin-top: 50px;
  font-size: 16px;
  border-radius: 20px;
}

.login-box button:hover {
  box-shadow: 1px 1px 10px #278f57;
  transition: all 0.2s ease-out;
}

.error {
  position: relative;
  width: 300px;
  height: 60px;
  color: #fff;
  background-color: #CF6679;
  padding: 10px;
  margin: 25px 0 -20px 0;
  border-radius: 10px;
  font-size: 15px;
  text-align: center;
  animation: errorMsg 300ms ease-in-out 0s forwards;
}

@keyframes errorMsg {
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
}