@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap');
:root {
  --color-primary: #122333;
  --color-secondary: #182b3c;
  --color-alt: #223c53;
  --color-neutral: #2eac68;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Space Grotesk', sans-serif;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: #fff;
  padding: 6px;
  background-color: var(--color-neutral);
  font-size: 14px;
  transition: all 200ms ease-in-out;
}

button:hover {
  box-shadow: 1px 1px 10px var(--color-neutral);
}

button:disabled {
  cursor: not-allowed;
  background-color: grey;
}

input {
  background-color: var(--color-secondary);
  color: #fff;
  outline: none;
  border: none;
  padding: 12px 10px 12px 40px; 
  margin: 8px 0;
}

textarea {
  background-color: var(--color-secondary);
  color: #fff;
  outline: none;
  border: none;
  padding: 15px;
  resize: none;
}