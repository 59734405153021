select {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    background-color: var(--color-secondary);
    color: #fff;
    font-size: 17px;
    padding: 10px;
    margin-left: 30px;
    text-align: center;
}

option {
    border: none;
    outline: none;
}