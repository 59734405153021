a {
    text-decoration: none;
}

.dashboard-wrapper {
    min-height: 100vh;
    background-color: var(--color-primary);
}

.github-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px;
}