.posts-main {
    min-height: 100vh;
    background-color: var(--color-primary);
}

.posts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.posts-action-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    height: 60px;
    background-color: var(--color-secondary);
    top: 40px;
}

.posts-action-bar h1 {
    color: #fff;
    margin-left: 40px;
    font-size: 18px;
}

.posts-action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
}

.new-post-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    cursor: pointer;
    background-color: var(--color-neutral);
    color: #fff;
    width: 100px;
    height: 30px;
    font-size: 14px;
}

.posts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin-top: 50px;
}

.post {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--color-secondary);
    width: 100%;
    height: 120px;
    margin: 10px;
    padding: 50px;
    transition: all 300ms ease-in-out;
}

.post:hover {
    padding-left: 80px;
    background-color: var(--color-alt);
}

.post-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.post-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-status {
    width: 80px;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    margin-left: 15px;
}

.live {
    background-color: var(--color-neutral);
}

.hidden {
    background-color: #a63232;
}

.post h1, h2, p {
    color: #fff;
}

.post-action-icon {
    cursor: pointer;
    color: #fff;
    width: 25px;
    height: 25px;
    margin: 10px;
}

.edit {
    margin-top: 15px;
    width: 30px;
}

.post-action-icon:hover {
    color: var(--color-neutral);
    box-shadow: 1px 1px 5px var(--color-alt);
}

.edit:hover {
    color: var(--color-neutral);
}

.trash:hover {
    color: #a63232;
}
