nav {
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.text-logo {
    margin-left: 100px;
    width: 40px;
    height: 40px;
    transition: all 300ms ease-in-out;
}

.text-logo:hover {
    transform: scale(1.1);
}

nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 300px;
    color: #fff;
    list-style: none;
    margin-right: 100px;
}

.navbar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    height: 50px;
    color: #fff;
}

.navbar-item:hover {
    border-bottom: 2px solid var(--color-neutral);
}

.active {
    border-bottom: 2px solid var(--color-neutral);
}

nav button {
    cursor: pointer;
    border: none;
    color: #fff;
    background-color: var(--color-neutral);
    width: 100px;
    height: 40px;
    margin-right: 100px;
    border-radius: 50px;
}

nav button:hover {
    box-shadow: 1px 1px 10px #278f57;
}