.new-post-main {
    min-height: 100vh;
    background-color: var(--color-primary);
}

.new-post-main .hide, .new-section-wrapper .hide {
    display: none;
}

.new-post-wrapper, .new-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.meta-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 1000px;
    height: 100px;
    margin-top: 50px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    color-scheme: dark;
}

.public-toggle {
    margin: 0;
    padding: 0;
    width: 50px;
}

.icon {
    position: relative;
    left: -238px;
    color: #fff;
}
.headerimg-icon {
    position: relative;
    top: 36px;
    left: 12px;
    color: #fff;
}

.new-section-icon {
    position: relative;
    right: 480px;
    top: -30px;
    color: #fff;
}

.content-data {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: -40px;
}

.meta-title {
    position: relative;
    top: 50px;
    left: -390px;
    color: #fff;
}

.sections-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 948px;
}

.sections-title {
    color: #fff;
}

.sections-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 948px;
    height: 100%;
    margin-top: 10px;
}

.section {
    cursor: move;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-secondary);
    width: 100%;
    height: 50px;
    margin-top: 5px;
    transition: all 200ms ease-in-out;
}

.section:hover {
    background-color: var(--color-alt);
    padding-left: 10px;
}

.section h1 {
    margin-left: 20px;
    color: #fff;
    font-size: 18px;
    width: 250px;
    white-space: nowrap;
}

.section-actions {
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 10px;
}

.section-icon {
    cursor: pointer;
    margin: 5px;
    width: 20px;
    height: 20px;
}

.submit-section {
    margin-top: 50px;
}

.submit-section button {
    padding: 10px;
    width: 500px;
}

.new-section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1000px;
    margin-top: 40px;
}

.new-section-header input {
    width: 100%;
    margin: 2px 0;
}

.new-section-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1000px;
    color: #fff;
    margin-bottom: 40px;
}
.new-section-helper {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.new-section-helper h1 {
    font-size: 18px;
    margin-bottom: 5px;
}

.new-section-helper p {
    font-size: 14px;
    color: rgb(158, 158, 158);
    margin-bottom: 30px;
}

.new-section-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 100px;
}

.new-section-actions button {
    width: 150px;
}